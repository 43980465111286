import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import styles from './HeaderNavigation.module.scss';
import CategoriesTree from './navigation/category/CategoriesTree';

interface HeaderNavigation {
  categories: any;
  isStickyHeader?: any;
  isInvertedClass?: any;
}
export const HeaderNavigation = ({ categories = [], isStickyHeader, isInvertedClass }: HeaderNavigation) => {
  return (
    <div className={styles.headerNavigation}>
      <div className={clsx(styles.headerNavigationItem, styles.menu)}>
        <div className={styles.category}>
          {categories && (
            <CategoriesTree isStickyHeader={isStickyHeader} categories={categories} isInvertedClass={isInvertedClass} />
          )}
        </div>
      </div>
    </div>
  );
};
