import React, { useState } from 'react';
import Typography from 'components/commercetools-ui/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { Review, addToReview } from 'frontastic/actions/review';
import StarRating from './StarRating';

export interface ProductReviewProps {
  headline?: string;
  description?: string;
  inputPlaceholder?: string;
  ctaLabel?: string;
  successTitle?: string;
  successMessage?: string;
  disclaimer?: string;
  reviews?: any;
}

export default function ProductReview({ reviews }: ProductReviewProps) {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatReviewMessage } = useFormat({ name: 'reiew' });

  const [hover, setHover] = useState(null);

  const feedbackHeadline = formatReviewMessage({
    id: 'headline.feedback',
    defaultMessage: "Customer's feedback",
  });
  const ratingTxt = formatReviewMessage({
    id: 'rating',
    defaultMessage: 'Rating',
  });
  const description = formatReviewMessage({
    id: 'description',
    defaultMessage: 'Description',
  });
  const subject = formatReviewMessage({
    id: 'subject',
    defaultMessage: 'Subject',
  });
  const name = formatReviewMessage({
    id: 'name',
    defaultMessage: 'Name',
  });
  const feedbackCta = formatReviewMessage({
    id: 'cta.feedback',
    defaultMessage: 'Send',
  });
  const feedbackSuccessMessage = formatReviewMessage({
    id: 'success.feedback.message',
    defaultMessage: 'Submited successful.',
  });
  const initState = { name: '', subject: '', description: '', rating: 0 };
  const [data, setData] = useState(initState);
  const [isConfirm, setIsConfirm] = useState(false);

  const onChange = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const setRatingFn = (value) => {
    setData({ ...data, rating: value });
  };
  const setHoverFn = (value) => {
    setHover(value);
  };

  //submission handler
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    //validate
    const isValid = !!data.description && !!data.name && !!data.subject && !!data.rating;
    if (!isValid) {
      return alert(formatErrorMessage({ id: 'enter.fields', defaultMessage: 'Please enter the required fields.' }));
    }
    await addToReview(data);
    setIsConfirm(true);
    setData(initState);
  };

  return (
    <div className="mx-auto max-w-7xl py-2 dark:bg-primary-200 lg:px-0">
      <div className="relative py-3 text-neutral-700 md:py-6 lg:py-9">
        <div>
          <h2 className="my-2 text-center text-2xl font-bold tracking-[0.010em] dark:text-light-100 md:text-left">
            {feedbackHeadline}
          </h2>
          {reviews && (
            <div>
              {(reviews || []).map((item: Review, idx) => {
                return (
                  <div className="border-b py-4" key={idx}>
                    <p className="py-2 font-bold">{item.subject}</p>
                    <div className="grid grid-cols-3 items-start">
                      <div className="col-span-1 sm:flex">
                        <span className="inline-block pr-2">{ratingTxt}</span>
                        <StarRating
                          rating={item.rating}
                          maxValue={5}
                          size={24}
                          hover={hover}
                          onChangeHover={() => {}}
                          onChangeValue={() => {}}
                          fillColor={'#edaa10'}
                          emptyColor={'grey'}
                        />
                      </div>
                      <div className="col-span-2">
                        <p className="mb-4">{item.description}</p>
                        <p>
                          Commented by {item.name} {item.date}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="mx-auto mt-8 max-w-5xl sm:w-full">
          <form action="#" className="mt-4 sm:mx-auto" onSubmit={onSubmit}>
            <div className="mb-3 grow">
              <label htmlFor="cta-rating">
                <Typography>{ratingTxt}</Typography>
              </label>
              <div>
                <StarRating
                  rating={data.rating}
                  hover={hover}
                  maxValue={5}
                  onChangeHover={setHoverFn}
                  onChangeValue={setRatingFn}
                  size={50}
                  fillColor={'#edaa10'}
                  emptyColor={'grey'}
                />
              </div>
            </div>
            <div className="mb-3 grow">
              <label htmlFor="cta-name">
                <Typography>{name}</Typography>
                <span className="text-red-500">*</span>
              </label>
              <input
                id="cta-name"
                name="name"
                type="text"
                className="block w-full rounded-sm border py-3 px-5 text-sm leading-5 text-gray-600 shadow-sm placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-accent-400"
                onChange={onChange}
              />
            </div>
            <div className="mb-3 grow">
              <label htmlFor="cta-subject">
                <Typography>{subject}</Typography>
                <span className="text-red-500">*</span>
              </label>
              <input
                id="cta-subject"
                name="subject"
                type="text"
                className="block w-full rounded-sm border py-3 px-5 text-sm leading-5 text-gray-600 shadow-sm placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-accent-400"
                onChange={onChange}
              />
            </div>
            <div className="mb-3 grow">
              <label htmlFor="cta-description">
                <Typography>{description}</Typography>
                <span className="text-red-500">*</span>
              </label>
              <textarea
                id="cta-description"
                name="description"
                onChange={onChange}
                className="block w-full rounded-sm border py-3 px-5 text-sm leading-5 text-gray-600 shadow-sm placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-accent-400"
              />
            </div>
            <div className="mt-4 shrink-0 sm:mt-0">
              <button
                type="submit"
                className="block rounded-sm bg-accent-400 py-3 px-5 text-base font-bold leading-5 text-white hover:bg-accent-500 sm:px-6"
              >
                <Typography>{feedbackCta}</Typography>
              </button>
            </div>
          </form>
        </div>
        {isConfirm && (
          <div className="relative py-4">
            <div className="sm:text-center">
              <p className="mx-auto mt-1 max-w-2xl text-center text-lg font-bold text-red-500">
                <Typography>{feedbackSuccessMessage}</Typography>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
