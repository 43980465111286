import React from 'react';

import { MediaSection } from 'components/merkle-ui/media-section';
const MediaBannerTastic = ({ data }: { data: any }) => {
  const {
    backgroundColor,
    boxWidth,
    buttonTitle,
    buttonType,
    buttonUrl,
    desktopHeight,
    image,
    imageMobile,
    mobileHeight,
    tabletHeight,
    textColor,
    title,
    titleAlignment,
    verticalPosition,
    videoUrl,
    videoUrlMobile,
  } = data;

  return (
    <MediaSection
      backgroundColor={backgroundColor}
      boxWidth={boxWidth}
      buttonTitle={buttonTitle}
      buttonType={buttonType}
      buttonUrl={buttonUrl}
      desktopHeight={desktopHeight}
      image={image}
      imageMobile={imageMobile}
      mobileHeight={mobileHeight}
      tabletHeight={tabletHeight}
      textColor={textColor}
      title={title}
      titleAlignment={titleAlignment}
      verticalPosition={verticalPosition}
      videoUrl={videoUrl}
      videoUrlMobile={videoUrlMobile}
    />
  );
};

export default MediaBannerTastic;
