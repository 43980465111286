import React from 'react';
import { Tile } from 'components/true-ui/tile';
import { Grid } from 'components/true-ui/grid';
import { Carousel } from 'components/merkle-ui/carousel';
const TrueTileSliderTastic = ({ data }: { data: any }) => {
  const {
    colNumberDesktop,
    colNumberMobile,
    colNumberTablet,
    enableSlider,
    label,
    showSliderArrowsDesktop,
    showSliderArrowsMobile,
    showSliderArrowsTablet,
    tiles,
    title,
    subtitle,
  } = data || {};

  if (!enableSlider) {
    return (
      <Grid
        desktopColumns={colNumberDesktop}
        tabletColumns={colNumberTablet}
        mobileColumns={colNumberMobile}
        title={title}
        subtitle={subtitle}
      >
        {tiles?.map((tile: any) => (
          <Tile {...tile} />
        ))}
      </Grid>
    );
  }

  return (
    <Carousel
      desktopColumns={colNumberDesktop}
      tabletColumns={colNumberTablet}
      mobileColumns={colNumberMobile}
      title={title}
      subtitle={subtitle}
    >
      {tiles?.map((tile: any) => (
        <Tile {...tile} />
      ))}
    </Carousel>
  );
};

export default TrueTileSliderTastic;
