/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import SwiperCore, { Lazy, Navigation, Scrollbar } from 'swiper';
// eslint-disable-next-line import/order
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, Scrollbar, Lazy]);

// Import Swiper styles
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
import styles from './index.module.scss';

export const Carousel = (props) => {
  const { title, children, desktopColumns, tabletColumns, mobileColumns } = props;

  const breakpoints = {
    320: {
      spaceBetween: 16,
      slidesPerView: mobileColumns || 1,
      initialSlide: 0,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
    },
    680: {
      spaceBetween: 12,
      slidesPerView: tabletColumns || 3,
      initialSlide: 0,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 20,
    },
    980: {
      spaceBetween: 16,
      slidesPerView: desktopColumns || 3,
      initialSlide: 0,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 40,
    },
  };

  const carousel = useCallback(
    (children) => (
      <div className={styles.categoriesCarousel}>
        <Swiper
          className="tileSwiper"
          modules={[Navigation]}
          breakpoints={breakpoints}
          navigation={{
            prevEl: '.swiper-prev-arrow',
            nextEl: '.swiper-next-arrow',
          }}
        >
          {children.map((child, index) => {
            return (
              <SwiperSlide key={index} className={styles.slide}>
                <>{child}</>
              </SwiperSlide>
            );
          })}
          {/*           <div
            style={{
              display: isArrowRight ? 'block' : 'none',
            }}
          >
            <div className={`swiper-prev-arrow ${styles.arrowNavigation} ${styles.arrowNavigationLeft}`}>
              <ArrowLeft width="30px" height="30px" color="#000" />
            </div>
            <div className={`swiper-next-arrow ${styles.arrowNavigation} ${styles.arrowNavigationRight}`}>
              <ArrowRight width="30px" height="30px" color="#000" />
            </div>
          </div>
          {!withoutScroll && <div className={`swiper-scrollbar js-swiper-scrollbar ${styles.scrollbar}`} />} */}
        </Swiper>
      </div>
    ),
    [children],
  );

  if (!children || !Array.from(children).length) return null;

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.featuredCarousel}>
        <div className={styles.carouselWrapper}>{carousel(children)}</div>
      </div>
    </div>
  );
};
