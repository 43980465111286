import React from 'react';
import LanguageSwitcher from 'components/commercetools-ui/language-switcher';
import Typography from 'components/commercetools-ui/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import Column, { Link, Column as FooterColumn } from './column';
import { renderIcon } from './renderIcon';
import { SocialLink } from './socialLink';
export interface Props {
  columns: FooterColumn[];
  copyright?: string;
  copyrightLinks?: Link[];
  socialLinks: any[];
}
import styles from './index.module.scss';
const Footer: React.FC<Props> = ({ columns, copyright, copyrightLinks, socialLinks }) => {
  const { formatMessage } = useFormat({ name: 'common' });

  return (
    <footer aria-label="footer" className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.footerGrid}>
          {columns?.map((column, index) => (
            <Column column={column} key={index} />
          ))}
        </div>
        {socialLinks && (
          <div className={styles.socialLinksColumn}>
            {socialLinks?.map((item, i) => (
              <SocialLink link={item} key={i} />
            ))}
          </div>
        )}

        {/*  <div className="mx-auto max-w-5xl py-10 px-2 xl:grid xl:grid-cols-2 xl:gap-8">
          <div
            className={`grid grid-cols-1 gap-10 md:gap-10 md:grid-cols-${(
              columns.length + 1
            ).toString()} xl:col-span-2`}
          >
            <div className="justify-start md:justify-center">
              <div className="flex space-x-2 md:justify-start">
                {renderIcon('speaker')}
                <h3 className="text-sm font-medium text-gray-800 dark:text-light-100">
                  <Typography>{formatMessage({ id: 'language', defaultMessage: 'Language' })}</Typography>
                </h3>
              </div>
              <LanguageSwitcher className="p-4 md:px-8" />
            </div>
          </div>
        </div> */}
      </div>
      {copyright && (
        <div className={styles.copyright}>
          <div className={styles.inner}>
            <p className="text-xs text-white sm:text-sm">© {copyright}</p>
            <ul className="flex">
              {copyrightLinks?.map((item, i) => (
                <li key={i} className="text-xs">
                  <p className="px-2 text-gray-300 hover:text-white sm:text-sm">
                    <Typography>{item.name}</Typography>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
