/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styles from './index.module.scss';

export const Grid = (props) => {
  const { title, desktopColumns, tabletColumns, mobileColumns, children } = props;

  return (
    <div
      className={styles.root}
      style={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        '--desktop-columns': desktopColumns,
        '--tablet-columns': tabletColumns,
        '--mobile-columns': mobileColumns,
      }}
    >
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.grid}>{children}</div>
    </div>
  );
};
