import React from 'react';
import clsx from 'clsx';
import Typography from 'components/commercetools-ui/typography';
import { Reference, ReferenceLink } from 'helpers/reference';
import styles from './index.module.scss';
import { renderIcon } from './renderIcon';
export interface Link {
  name: string;
  reference: Reference;
  asHeading?: boolean;
}

export interface Column {
  icon?: string;
  header: string;
  links: Link[];
}

export interface Props {
  column: Column;
}

const Column: React.FC<Props> = ({ column }) => {
  return (
    <ul role="list" className={styles.column}>
      {column.links.map((item, i) => (
        <li key={i}>
          <ReferenceLink target={item.reference} className={clsx(styles.link, item?.asHeading && styles.linkHeading)}>
            <Typography>{item.name}</Typography>
          </ReferenceLink>
        </li>
      ))}
    </ul>
  );
};

export default Column;
