import React from 'react';
import { HeartIcon } from '@heroicons/react/outline';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';

interface WishListButtonProps {
  wishlistItemCount?: number;
  wishlistLink?: Reference;
}

const WishListButton: React.FC<WishListButtonProps> = ({ wishlistItemCount, wishlistLink }) => {
  //i18n messages
  const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });

  return (
    <div className="flow-root pr-3">
      <ReferenceLink
        ariaLabel="wishlist button"
        target={wishlistLink}
        className="group relative -m-2 flex items-center p-2"
      >
        <svg
          aria-hidden="true"
          className="pre-nav-design-icon"
          focusable="false"
          viewBox="0 0 24 24"
          role="img"
          width="24px"
          height="24px"
          fill="none"
        >
          <path
            stroke="currentColor"
            strokeWidth="1.5"
            d="M16.794 3.75c1.324 0 2.568.516 3.504 1.451a4.96 4.96 0 010 7.008L12 20.508l-8.299-8.299a4.96 4.96 0 010-7.007A4.923 4.923 0 017.205 3.75c1.324 0 2.568.516 3.504 1.451l.76.76.531.531.53-.531.76-.76a4.926 4.926 0 013.504-1.451"
          ></path>
        </svg>
        {wishlistItemCount > 0 && (
          <>
            <span className="absolute -top-0 -right-1 h-4 w-4 rounded-full bg-accent-400 hover:bg-accent-500">
              <span className="flex h-full w-full items-center justify-center text-xs text-white group-hover:text-white">
                {wishlistItemCount}
              </span>
            </span>
            <span className="sr-only">
              {formatWishlistMessage({
                id: 'wishlist.items.in.view',
                defaultMessage: 'items in wishlist, view wishlist',
              })}
            </span>
          </>
        )}
      </ReferenceLink>
    </div>
  );
};

export default WishListButton;
