import React from 'react';
import TrueNewsletter, { TrueNewsletterProps } from 'components/true-ui/newsletter-true';

interface Props {
  data: TrueNewsletterProps;
}

const TrueNewsletterTastic: React.FC<Props> = ({ data }) => {
  return <TrueNewsletter {...data} />;
};

export default TrueNewsletterTastic;
