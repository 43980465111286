import clsx from 'clsx';
import { ReferenceLink } from 'helpers/reference';
import Image from 'frontastic/lib/image';
import CartButton from '../../commercetools-ui/header/cart-button';
import WishListButton from '../../commercetools-ui/header/wishlist-button';
import styles from './Header.module.scss';
import { HeaderNavigation } from './HeaderNavigation';
import SearchInput from './Search';

export const Header = ({
  categories,
  logo,
  wishlistItemCount,
  cartItemCount,
  cartLink,
  wishlistLink,
  accountLink,
  account,
}) => {
  return (
    <header className={styles.header}>
      <div className={styles.topBar}>
        <div className={styles.topBarInner}>
          <nav>
            <ul className={styles.topBarLinks}>
              <li>
                <span>Find a store</span>
              </li>
              <li className="separator-top-menu">|</li>
              <li>
                <span>Help</span>
              </li>
              <li className="separator-top-menu">|</li>
              <li>
                <ReferenceLink target={accountLink} className={styles.accountLink}>
                  <svg
                    aria-hidden="true"
                    className="pre-nav-design-icon"
                    focusable="false"
                    viewBox="0 0 24 24"
                    role="img"
                    width="24px"
                    height="24px"
                    fill="none"
                    data-var="glyph"
                  >
                    <path
                      stroke="currentColor"
                      strokeWidth="2"
                      d="M3.75 21v-3a3.75 3.75 0 013.75-3.75h9A3.75 3.75 0 0120.25 18v3"
                    ></path>
                    <path
                      stroke="currentColor"
                      strokeWidth="2"
                      d="M15.75 7.5a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </ReferenceLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div id="stickyHeader" className={clsx(styles.stickyHeader)}>
        <div className={clsx(styles.headerContainer)}>
          {/* Logo */}
          <ReferenceLink
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            target="/"
            className={styles.logo}
          >
            <Image media={logo} className="dark:invert" layout="fill" objectFit="contain" alt="Logo" />
          </ReferenceLink>

          {categories && <HeaderNavigation isInvertedClass={styles.isInvertedItem} categories={categories} />}
          <div className="flex flex-1 items-center justify-end">
            <SearchInput />
            <WishListButton wishlistItemCount={wishlistItemCount} wishlistLink={wishlistLink} />
            <CartButton cartItemCount={cartItemCount} cartLink={cartLink} />
          </div>
        </div>
      </div>
    </header>
  );
};
