import Typography from 'components/commercetools-ui/typography';
import { Reference, ReferenceLink } from 'helpers/reference';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import styles from './index.module.scss';
export interface TileProps {
  image: MediaItemWithMods;
  title: string;
  titleColor?: string;
  subtitle: string;
  subtitleColor?: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  titleFont?: string;
  subtitleFont?: string;
}

const Tile: React.FC<TileProps> = ({
  image,
  title,
  titleColor = 'text-black',
  titleFont = 'font-sans',
  subtitle,
  subtitleColor = 'text-black',
  subtitleFont = 'font-sans',
  ctaLabel,
  ctaReference,
}) => {
  return (
    <div className="relative">
      {ctaReference && (
        <ReferenceLink target={ctaReference}>
          <div className="relative flex flex-col items-center justify-center overflow-hidden bg-[#F4F4F4] p-2 align-middle">
            {image && (
              <div className="w-full">
                <Image media={image} className={styles.image} alt={title} />
              </div>
            )}

            <div className="flex max-w-md -translate-y-1/2 flex-col justify-center text-center md:left-10 md:max-w-xl md:text-left">
              <div className={`text-md mb-2 md:font-medium ${subtitleFont} ${subtitleColor}`}>
                <Typography>{subtitle}</Typography>
              </div>
              <h2 className={`text-cente text-baser ${titleFont} ${titleColor}`}>
                <Typography>{title}</Typography>
              </h2>
            </div>
          </div>
        </ReferenceLink>
      )}
    </div>
  );
};

export default Tile;
