import { Product } from '@Types/product/Product';
import ProductReview from 'components/hanh-components/products/review';
import { useReview } from 'frontastic/provider';

function ProductReviewTastic({ data }) {
  // Need feedback list
  const { product }: { product: Product } = data?.data?.dataSource;
  // const reviews = useReview().data;
  const reviews = [
    {
      description:
        'Please create new component product review in product detail page create new component product review in product detail page create new component product review in product detail page create new component product review in product detail page',
      rating: 5,
      name: 'Cao Linh',
      date: '16/09/2021',
      subject: 'chat luong tot',
    },
    {
      description: 'Please create new component product review in product detail page',
      rating: 4,
      name: 'Cao Linh',
      date: '16/09/2021',
      subject: 'chat luong kha',
    },
    {
      description: 'Please create new component product review in product detail page',
      rating: 2,
      name: 'Cao Linh',
      date: '16/09/2021',
      subject: 'chat luong tot',
    },
    {
      description: 'Please create new component product review in product detail page',
      rating: 4,
      name: 'Cao Linh',
      date: '16/09/2021',
      subject: 'chat luong tot',
    },
  ];
  return <ProductReview reviews={reviews} />;
}

export default ProductReviewTastic;
