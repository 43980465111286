import React from 'react';
import { useCart, useWishlist, useAccount } from 'frontastic/provider';
import { calculateCartCount } from 'helpers/utils/calculateCartCount';

import { Header } from '../../../components/merkle-ui/header/Header';
const MerkleHeaderTastic = ({ data, previewId }) => {
  const { data: cart } = useCart();
  const { data: wishlist } = useWishlist();
  const { account } = useAccount();

  const { links, logo, promoBanner, promoBannerLink, promoText, menulinks, accountLink, wishlistLink, cartLink } =
    data || {};
  return (
    <Header
      categories={menulinks}
      logo={logo}
      cartItemCount={calculateCartCount(cart?.lineItems) || 0}
      wishlistItemCount={wishlist?.lineItems?.length || 0}
      account={account}
      accountLink={accountLink}
      wishlistLink={wishlistLink}
      cartLink={cartLink}
    />
  );
};

export default MerkleHeaderTastic;
