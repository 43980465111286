import React from 'react';
import Footer from 'components/merkle-ui/footer';
import Highlights from 'components/merkle-ui/footer/highlights';

const FooterTastic = ({ data }) => {
  console.log(data);
  const columns = [
    {
      header: data.headerCol1,
      links: data.linksCol1,
      icon: data.iconCol1,
    },
    {
      header: data.headerCol2,
      links: data.linksCol2,
      icon: data.iconCol2,
    },
    {
      header: data.headerCol3,
      links: data.linksCol3,
      icon: data.iconCol3,
    },
    {
      header: data.headerCol4,
      links: data.linksCol4,
      icon: data.iconCol4,
    },
  ];

  return (
    <Footer
      columns={columns}
      copyright={data.copyright}
      copyrightLinks={data.copyrightLinks}
      socialLinks={data.socialLinks}
    />
  );
};

export default FooterTastic;
