import React from 'react';

import { CallToActionSection } from 'components/merkle-ui/call-to-action';
const CallToActionTastic = ({ data }: { data: any }) => {
  const { title, subTitle, titleAlignment, buttonTitle, buttonType, buttonUrl } = data || {};
  return (
    <CallToActionSection
      title={title}
      subtitle={subTitle}
      alignment={titleAlignment}
      buttonType={buttonType}
      buttonUrl={buttonUrl}
      buttonTitle={buttonTitle}
    />
  );
};

export default CallToActionTastic;
