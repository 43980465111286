/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import clsx from 'clsx';
import Typography from 'components/commercetools-ui/typography';
import { ReferenceLink } from 'helpers/reference';
import styles from './Button.module.scss';
/**
 * Buttons are Bootstrap's core component for triggering various actions. In Shards, they're very flxible, support multiple sizes, styles, states and many more.
 */

interface Button {
  link?: any;
  type?: any;
  title?: any;
  ref?: any;
}
// eslint-disable-next-line react/display-name
export const Button = forwardRef(({ link, type, title, ref }: Button) => {
  const customInnerClasses = clsx(styles.button, styles[type]);

  return link ? (
    <ReferenceLink target={link} className={customInnerClasses}>
      <Typography>{title}</Typography>
    </ReferenceLink>
  ) : (
    <button>
      <span className={styles.label}>{title}</span>
      {/* {as !== 'text' && <span className={styles.shadowElement} />} */}
    </button>
  );
});
