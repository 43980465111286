import React from 'react';
import clsx from 'clsx';
// import Reference from '@frontastic/catwalk/src/js/component/reference';
import Typography from 'components/commercetools-ui/typography';
import { ReferenceLink } from 'helpers/reference';
import Image from 'frontastic/lib/image';
import { Button } from '../button/Button';
import styles from './index.module.scss';
export const Tile = (props) => {
  const { tileImage, tileSubheading, tileHeading, buttons, tileVariant } = props;
  // default aspect ratio 3:4
  const wrapperButton = buttons?.filter((button) => button.isLinkCovered === true)[0];

  const renderButtons = () => {
    return (
      buttons &&
      buttons.map((button) => {
        if (button.shouldHideButton === true) return null;
        console.log(button.shouldHideButton);
        return (
          <Button
            key={button.buttonTitle}
            title={button.buttonTitle}
            type={button.buttonType}
            link={button.buttonUrl}
          />
        );
      })
    );
  };

  const renderImage = () => {
    return (
      tileImage && (
        <span className={styles.media}>
          <Image objectFit="cover" layout="fill" media={tileImage.media} alt={tileImage?.alt} />
        </span>
      )
    );
  };

  const renderContentBlock = () => (
    <>
      {tileHeading && <span className={styles.title}>{tileHeading}</span>}
      {tileSubheading && <span className={styles.subtitle}>{tileSubheading}</span>}
      <div className={styles.buttons}>{renderButtons()}</div>
    </>
  );

  console.log(tileVariant);
  return (
    <div className={clsx(styles.card, styles[tileVariant])}>
      {wrapperButton && (
        <ReferenceLink target={wrapperButton.buttonUrl} className={styles.wrapperLink}>
          <Typography>{wrapperButton.buttonTitle}</Typography>
        </ReferenceLink>
      )}
      {tileImage && (
        <>
          <figure className={styles.image}>
            {tileVariant !== 'version3' ? (
              <>
                {renderImage()}
                <figcaption>{renderContentBlock()}</figcaption>
              </>
            ) : (
              renderImage()
            )}
          </figure>
        </>
      )}
      <>{tileVariant === 'version3' && renderContentBlock()}</>
    </div>
  );
};
