import React from 'react';
// import Slider, { SliderProps } from './../commercetools-ui/slider';
import Slider, { SliderProps } from 'components/commercetools-ui/slider';
import { ReferenceLink, Reference } from 'helpers/reference';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';

export interface Props {
  images: [];
  title: string;
  subline?: string;
  ctaLabel: string;
  ctaLink: Reference;
}
export type ImageBanner = {
  image: MediaItemWithMods;
  titleImage: string;
  subTitleImage: string;
};

export default function BannerSlider({ images, title, subline, ctaLabel, ctaLink }: Props) {
  const sliderFixedMood: SliderProps = {
    slidesPerView: 1,
    arrows: true,
    fitToSlides: true,
    overlapDots: true,
    className: 'sm:max-h-96 max-h-56',
  };

  const sliderConfiguration: SliderProps = sliderFixedMood;

  return (
    <div className="lg:max-auto lg:mx-auto">
      <div className="w-auto text-center">
        <h2 className="text-xl tracking-tight text-gray-400 dark:text-light-100">{subline}</h2>
        <h2 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-light-100">{title}</h2>
      </div>
      {ctaLabel && ctaLink && (
        <div className="flex items-center justify-end px-4 sm:px-6 lg:px-0">
          <ReferenceLink target={ctaLink} className="hidden text-sm font-semibold text-accent-400 sm:block">
            {ctaLabel}
            <span aria-hidden="true"> &rarr;</span>
          </ReferenceLink>
        </div>
      )}
      <div className="relative">
        <div className="banner-silder-cmt relative -mb-6 w-full overflow-x-auto pb-6">
          <Slider {...sliderConfiguration}>
            {images.map(
              (item: ImageBanner, index: number) =>
                item.image && (
                  <div key={item.image.media.mediaId} className="group relative aspect-3/4 flex-1 overflow-hidden">
                    <div className="absolute inset-0">
                      <div className="bg-black/0 group-hover:bg-black/70 absolute inset-0 top-16 left-32 z-10 text-white">
                        <p className="font-bold">{item.titleImage}</p>
                        <p className="text-lg font-thin">{item.subTitleImage}</p>
                      </div>
                      <Image
                        media={item.image}
                        alt={item.image.media.name}
                        className="transition duration-500 ease-in-out group-hover:scale-110"
                      />
                    </div>
                  </div>
                ),
            )}
          </Slider>
        </div>
      </div>

      {ctaLabel && ctaLink && (
        <div className="mt-12 flex px-4 sm:hidden">
          <ReferenceLink target={ctaLink} className="text-sm font-semibold text-accent-400 hover:text-accent-500">
            {ctaLabel}
            <span aria-hidden="true"> &rarr;</span>
          </ReferenceLink>
        </div>
      )}
    </div>
  );
}
