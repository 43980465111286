import React, { useEffect, useRef } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './Header.module.scss';
export interface Props extends React.ComponentProps<'input'> {
  onSubmit?: () => void;
}

const SearchInput: React.FC<Props> = ({ onSubmit, ...props }) => {
  //formatted messages
  const { formatMessage } = useFormat({ name: 'common' });

  //input ref
  const ref = useRef<HTMLInputElement>(null);

  //form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit?.();
  };

  useEffect(() => {
    //upon mounting we initially focus the input
    ref.current.focus();
  }, []);

  return (
    <form onSubmit={handleSubmit} className={styles.search}>
      <button
        type="submit"
        data-var="vsButton"
        aria-label="Open Search Modal"
        data-search-closed-label="Search"
        data-search-open-label="Open Search Modal"
      >
        <svg
          aria-hidden="true"
          className="pre-nav-design-icon"
          focusable="false"
          viewBox="0 0 24 24"
          role="img"
          width="24px"
          height="24px"
          fill="none"
        >
          <path
            stroke="currentColor"
            strokeWidth="1.5"
            d="M13.962 16.296a6.716 6.716 0 01-3.462.954 6.728 6.728 0 01-4.773-1.977A6.728 6.728 0 013.75 10.5c0-1.864.755-3.551 1.977-4.773A6.728 6.728 0 0110.5 3.75c1.864 0 3.551.755 4.773 1.977A6.728 6.728 0 0117.25 10.5a6.726 6.726 0 01-.921 3.407c-.517.882-.434 1.988.289 2.711l3.853 3.853"
          ></path>
        </svg>
      </button>
      <input
        type="text"
        name="search"
        id="search"
        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-accent-400 focus:ring-accent-400 sm:text-sm"
        placeholder={`${formatMessage({ id: 'search', defaultMessage: 'Search' })}...`}
        {...props}
        ref={ref}
      />
    </form>
  );
};

export default SearchInput;
