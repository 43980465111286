import React, { useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import { ChevronDownIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import { ReferenceLink } from '../../../../../helpers/reference';
import Typography from '../../../../commercetools-ui/typography';
import styles from './CategoriesTree.module.scss';

interface CategoriesTreeProps {
  categories: any;
  isStickyHeader?: any;
  isInvertedClass?: any;
}
interface CategoryProps {
  category: any;
}

function Category({ category }: CategoryProps) {
  const linkRef = useRef(null);
  const [isShowing, setIsShowing] = useState(false);
  const level = category.columns ? 1 : 2;
  const children = category.columns;
  const hasChildren = children && !!children.length;
  const { configuration, tree } = category;
  const isVisibleInMenu = configuration ? !configuration?.hideInMenu : !tree?.configuration?.hideInMenu;
  const [isDesktopSize] = useMediaQuery(desktop);

  const nestedChildren =
    hasChildren &&
    children.map((category, index) => (
      <div data-testid="category" className={styles.column} key={index}>
        <ReferenceLink target={category.reference}>
          <Typography>{category.name}</Typography>
        </ReferenceLink>
        {category?.helpLinks?.map((link, idx) => (
          <ReferenceLink target={link.reference} key={idx}>
            <Typography>{link.name}</Typography>
          </ReferenceLink>
        ))}
      </div>
    ));

  const topItems =
    level === 1 &&
    (hasChildren ? (
      <>
        {!isDesktopSize ? (
          <div className={styles.categoryHead}>
            <ReferenceLink
              target={category.reference}
              className={clsx(styles.topLink, isShowing && styles.topLinkActive)}
            >
              <Typography>{category.name}</Typography>
            </ReferenceLink>
            <ChevronDownIcon
              className="ml-2 -mr-1 h-5 w-5 text-black-400 hover:text-black-700"
              aria-hidden="true"
              onClick={() => {
                hasChildren && setIsShowing(!isShowing);
              }}
            />
          </div>
        ) : (
          <ReferenceLink
            target={category.reference}
            className={clsx(styles.topLink, isShowing && styles.topLinkActive)}
          >
            <Typography>{category.name}</Typography>
          </ReferenceLink>
        )}
        <Transition
          show={isShowing}
          appear={true}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          as="div"
          className={styles.popover}
        >
          <div className={styles.popoverInner}>{nestedChildren}</div>
        </Transition>
      </>
    ) : (
      <ReferenceLink target={category.reference} className={styles.topLink}>
        <Typography>{category.name}</Typography>
      </ReferenceLink>
    ));
  const categoryAttrs = !isDesktopSize
    ? {
        'data-testid': 'category',
        className: styles.mainItem,
        ref: linkRef,
      }
    : {
        'data-testid': 'category',
        className: styles.mainItem,
        ref: linkRef,
        onMouseOver: () => {
          hasChildren && setIsShowing(true);
        },
        onMouseLeave: () => {
          hasChildren && setIsShowing(false);
        },
      };
  return isVisibleInMenu && <div {...categoryAttrs}>{topItems}</div>;
}

const CategoriesTree = ({ categories, className = '' }) => {
  return (
    <nav itemType="http://www.schema.org/SiteNavigationElement" className={className}>
      {categories.map((category) => (
        <Category key={category.name} category={category} />
      ))}
    </nav>
  );
};

const CategoriesTreeResponsive = ({ categories }: CategoriesTreeProps) => {
  const [isDesktopSize] = useMediaQuery(desktop);
  const [open, setOpen] = useState(false);

  return !isDesktopSize ? (
    <>
      <div className="flex flex-1 items-center py-2 lg:py-0">
        <button
          type="button"
          className="-ml-2 rounded-md bg-none p-2 text-primary-400 dark:text-light-100"
          onClick={() => setOpen(!open)}
        >
          <span className="sr-only">Open menu</span>
          <MenuIcon className="h-7 w-7" aria-hidden="true" />
        </button>
      </div>
      <div className={`${open ? 'flex' : 'hidden'} ${styles.mobileMenu}`}>
        <div className="mb-5 flex px-4 pt-5 pb-2">
          <button
            type="button"
            className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 dark:text-light-100"
            onClick={() => setOpen(false)}
          >
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <CategoriesTree categories={categories} />
      </div>
    </>
  ) : (
    <CategoriesTree categories={categories} className={styles.desktopMenu} />
  );
};

CategoriesTreeResponsive.defaultProps = {
  categories: [],
  banner: null,
};

export default CategoriesTreeResponsive;
