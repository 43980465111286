import EmptyCart from 'components/commercetools-ui/cart/emptyCart';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import FormButton from '../checkout/checkout-form/fields/formButton';
import FormInput from '../checkout/checkout-form/fields/formInput';

export interface Props {
  readonly updateFormInput: (propName: string, newValue: string) => void;
  readonly submitForm: () => void;
  readonly checkoutData: { [inputName: string]: string };
}

const InvoicePayment = ({ updateFormInput, submitForm, checkoutData }: Props) => {
  //i18n messages
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  //cart data
  const { data } = useCart();

  if (!data?.lineItems || data.lineItems.length < 1) {
    return <EmptyCart />;
  }

  return (
    <section
      aria-labelledby="payment-heading"
      className="flex-auto overflow-y-auto px-4 pt-12 pb-16 sm:px-6 sm:pt-16 lg:px-8 lg:pt-0 lg:pb-24"
    >
      <FormButton
        buttonText={`${formatCheckoutMessage({
          id: 'pay',
          defaultMessage: 'Pay',
        })} ${CurrencyHelpers.formatForCurrency(data.sum)}`}
        onClick={submitForm}
        isDisabled={checkoutData.paymentMethod != 'invoice'}
      />
    </section>
  );
};

export default InvoicePayment;
