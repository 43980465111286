import clsx from 'clsx';
import { Button } from '../button/Button';
import styles from './index.module.scss';

export const CallToActionSection = ({
  title,
  subtitle,
  alignment = 'center',
  buttonTitle,
  buttonUrl,
  buttonType,
  videoUrl,
  image,
  imageModile,
}: {
  title?: string;
  subtitle?: string;
  alignment?: 'left' | 'center' | 'right';
  buttonTitle: string;
  buttonUrl: any;
  buttonType: string;
  videoUrl?: string;
  image?: any;
  imageModile?: any;
}) => {
  return (
    <div className={clsx(styles.callToActionSection, styles[`alignment_${alignment}`])}>
      <h2 className={styles.title}>{title}</h2>
      <p>{subtitle}</p>
      <Button link={buttonUrl} type={buttonType} title={buttonTitle} />
    </div>
  );
};
