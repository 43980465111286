import { useEffect, useState } from 'react';
import clsx from 'clsx';
import ReactPlayer from 'react-player/lazy';
import { ReferenceLink, type Reference } from 'helpers/reference';
import Image from 'frontastic/lib/image';
import { MediaItemWithMods, MediaItem } from 'frontastic/lib/image/types';
import styles from './index.module.scss';

export const MediaSection = ({
  backgroundColor,
  boxWidth,
  buttonTitle,
  buttonType,
  buttonUrl,
  desktopHeight,
  image,
  imageMobile,
  mobileHeight,
  tabletHeight,
  textColor,
  title,
  titleAlignment,
  verticalPosition,
  videoUrl,
  videoUrlMobile,
  videoAutoplay = true,
}) => {
  console.log(image);
  console.log(videoUrl);
  const [isPlaying, setIsPlaying] = useState(videoAutoplay);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const renderImage = () => {
    return image && !videoUrl ? (
      <div className="relative w-full">
        <Image objectFit="cover" media={image.media} alt={image?.alt} />
      </div>
    ) : null;
  };
  const renderVideo = () => {
    return (
      videoUrl && (
        <ReactPlayer
          light={
            !videoAutoplay && image ? <Image objectFit="cover" media={image.media} alt={image?.alt} /> : !videoAutoplay
          }
          url={videoUrl}
          playing={isPlaying}
          loop={true}
          playsinline={true}
          controls={false}
          autoplay={videoAutoplay}
          muted={true}
          width="100%"
          height="100%"
          className="absolute top-0 left-0"
        />
      )
    );
  };
  return (
    <div className={styles.root}>
      <div
        className={clsx(styles.inner)}
        style={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          '--desktop-height': desktopHeight + '%',
          '--mobile-height': mobileHeight ? mobileHeight + '%' : desktopHeight + '%',
          '--tablet-height': tabletHeight ? tabletHeight + '%' : desktopHeight + '%',
        }}
      >
        {renderImage()}
        {renderVideo()}
      </div>
    </div>
  );
};
