import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import AdyenCheckout from '@adyen/adyen-web';
import toast from 'react-hot-toast';
import { useCart, useAdyen } from 'frontastic';
import InvoicePayment from '../../invoice-payment/index';
import '@adyen/adyen-web/dist/adyen.css';
import FormRadioGroup from '../../checkout/checkout-form/fields/formRadioGroup';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { Payment, PaymentStatuses } from '@Types/cart/Payment';

type Session = {
  id: string;
  sessionData: string;
};

type SessionConfig = {
  environment: string;
  clientKey: string;
  session: Session;
};

const Checkout = () => {
  const router = useRouter();
  const { data: cartList, checkout, addPayment } = useCart();
  const { createSession } = useAdyen();
  const [session, setSession] = useState<Session>();
  const [isSessionCreated, setIsSessionCreated] = useState<boolean>(false);
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const paymentMethods = [
    {
      id: 'invoice',
      label: formatCheckoutMessage({ id: 'cod', defaultMessage: 'Invoice' }),
      value: 'invoice',
      default: true,
    },
    {
      id: 'adyen',
      label: formatCheckoutMessage({ id: 'adyen', defaultMessage: 'Credit Card With Adyen' }),
      value: 'adyen',
      default: false,
    },
  ];
  const initializeSession = async (sessionConfiguration: SessionConfig) => {
    const checkout = await AdyenCheckout(sessionConfiguration);
    checkout.create('dropin').mount('#dropin-container');
  };

  useEffect(() => {
    updatePaymentMethod('invoice');
    if (!isSessionCreated) {
      const host = typeof window !== 'undefined' ? window.location.origin : '';
      setIsSessionCreated(true);
      createSession(cartList.sum?.centAmount, cartList.sum?.currencyCode, `${host}/thank-you`).then((res) => {
        const { id, sessionData } = res;
        setSession({ id, sessionData });
      });
    }
  }, [cartList, createSession]);

  useEffect(() => {
    if (session) {
      const sessionConfiguration = {
        environment: 'test', //process.env.NODE_ENV === 'production' ? 'live' : 'test',
        clientKey: 'test_YW76IUBS6VHT5LVHY45OHSCWFAZV6SWT',
        session,
        onPaymentCompleted: (result) => {
          if (result.resultCode === 'Authorised' || result.resultCode === 'Received') {
            checkout().finally(() => {
              router.push('/thank-you');
            });
          }
        },
        onError: (error: Error) => {
          toast.error(error.message);
        },
      };

      initializeSession(sessionConfiguration);
    }
  }, [session]);

  //checkout data
  const [checkoutData, setCheckoutData] = useState({
    paymentMethod: '',
  });

  const updateFormInput = (propName: string, newValue: string) => {
    setCheckoutData({ ...checkoutData, [propName]: newValue });
  };

  const updatePaymentMethod = (method: string) => {
    updateFormInput('paymentMethod', method);
  };

  const submitForm = async () => {
    const method = checkoutData.paymentMethod;
    if (method == 'adyen') {
      return;
    }

    const payment = {
      amountPlanned: {
        centAmount: cartList.sum.centAmount,
        currencyCode: cartList.sum.currencyCode,
      },
    };

    await addPayment(payment);
    await checkout().then(() => {
      router.push('/thank-you');
    });
  };

  return (
    <>
      <section aria-labelledby="cart-heading" className="bg-white md:rounded md:shadow-md lg:col-span-7">
        <div className="mx-auto max-w-lg">
          <FormRadioGroup
            headline={formatCheckoutMessage({ id: 'payment', defaultMessage: 'Payment' })}
            subline={formatCheckoutMessage({
              id: 'askPaymentPreference',
              defaultMessage: 'What do you prefer to pay with?',
            })}
            options={paymentMethods}
            className="col-span-full"
            onChange={(val) => updatePaymentMethod(val)}
          />
          <div style={{ display: checkoutData.paymentMethod !== 'invoice' ? 'none' : '' }}>
            <InvoicePayment updateFormInput={updateFormInput} submitForm={submitForm} checkoutData={checkoutData} />
          </div>
          <div id="dropin-container" style={{ display: checkoutData.paymentMethod !== 'adyen' ? 'none' : '' }}></div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
